
import Vue from "vue";
import { Actions } from "@/store/models";
import Logo from "@/components/common/Logo.vue";
import { checkout } from "@/api";

export default Vue.extend({
  name: "PaymentFailed",
  components: { Logo },
  props: {
    prevCard: Object,
  },
  data() {
    return {
      imageBottom: require("/src/assets/bottom-pic-landscape.jpg"),
    };
  },
  computed: {},
  methods: {
    async logout() {
      await this.$store.dispatch(Actions.Logout);
    },
    tryAgain() {
      this.$emit("tryAgain");
    },
  },
});
