
import Vue from "vue";
import Logo from "@/components/common/Logo.vue";

export default Vue.extend({
  name: "PaymentProcessed",
  components: { Logo },
  data() {
    return {
      image: require("/src/assets/Vertical-Gas-Station-quarter-container.jpg"),
      invites: [{ exists: false, email: "" }],
      isMember: false, // to do
    };
  },
  computed: {
    title: function (): string {
      return "Success! Your billing information has been updated and payment has been processed.";
    },
  },
  methods: {
    toDashboard() {
      this.$router.push("/dashboard");
    },
    toAccount() {
      this.$router.push("/accountmanagement");
    },
  },
});
