
import { ICompany, IInvoice } from "@/api";
import { getDatetime } from "@/utils";
import Vue from "vue";

export default Vue.extend({
  name: "InvoicesDialog",
  components: {},
  props: {
    dialog: Boolean,
    invoices: Array
  },
  data() {
    return {
      headers: [
        { text: "DATE ISSUED", value: "created_datetime" },
        // { text: "INVOICE #", value: "id" },
        { text: "AMOUNT", value: "invoice_amount" },
      ],
    };
  },
  computed: {
    company: function (): ICompany {
      return this.$store.state.company;
    },
    items: function (): IInvoice[] {
      // const result = [] 
      this.invoices?.forEach((invoice: any) => {
        invoice.invoice_amount = "$" + invoice.invoice_amount
        invoice.created_datetime = getDatetime(invoice.created_datetime)
      })
      return this.invoices as any
    }
  },
  methods: {
    close() {
      this.$emit("update:dialog", false);
    }
  },
  watch: {},
});
